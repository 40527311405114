// custom typefaces
import 'typeface-montserrat';
import 'typeface-merriweather';

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  // if (typeof window.IntersectionObserver === `undefined`) {
  //   import(`intersection-observer`);
  //   console.log(`# IntersectionObserver is polyfilled!`);
  // }
};
